import { CartItemFragment } from '@graphcommerce/magento-cart-items'

type CartItemPrices = NonNullable<CartItemFragment['prices']>

export const cutrBaseUrl =
  import.meta.graphCommerce.cutrBaseUrl ?? 'https://verdouw.cutlist.gcp-staging.cutr.com'

export const squashProfilingCartItems = (cartItems: Array<CartItemFragment>) => {
  if (cartItems.length < 1) return cartItems

  const PROFILING_SKU = '186505'
  const PROFILING_STARTUP_SKU = '186527'

  const cartItemsWithoutProfiling = cartItems.filter((item) => item.product.sku !== PROFILING_SKU)
  const profilingStartupFeeIndex =
    cartItemsWithoutProfiling.findIndex((item) => item.product.sku === PROFILING_STARTUP_SKU) + 1

  const profilingCartItems = cartItems.filter((item) => item.product.sku === PROFILING_SKU)

  if (profilingCartItems.length === 0) return cartItemsWithoutProfiling

  const totalProfilingPrice = profilingCartItems.reduce(
    (acc, current) => acc + Number(current.prices?.row_total.value),
    0,
  )

  const squashedProfilingItem: CartItemFragment = {
    ...profilingCartItems[0],
    cutlist_description: 'Profilering',
    prices: {
      ...(profilingCartItems[0]?.prices as CartItemPrices),
      row_total: { value: totalProfilingPrice, currency: 'EUR' },
    },
  }

  const updatedCartItems = [...cartItemsWithoutProfiling]
  updatedCartItems.splice(profilingStartupFeeIndex, 0, squashedProfilingItem)

  return updatedCartItems
}
