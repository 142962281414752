/* eslint-disable */
import * as Types from '@graphcommerce/graphql-mesh/.mesh';

import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

export const CustomerStatusDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"CustomerStatus"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"customer"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"opMaat_contactPersonStatus"}}]}}]}}]} as unknown as DocumentNode<CustomerStatusQuery, CustomerStatusQueryVariables>;
export type CustomerStatusQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type CustomerStatusQuery = { customer?: { opMaat_contactPersonStatus?: string | null } | null };
