import { Image } from '@graphcommerce/image'
import { ProductListItemFragment, useProductLink } from '@graphcommerce/magento-product'
import { IconSvg } from '@graphcommerce/next-ui'
import { iconChevronRight } from '@graphcommerce/next-ui/icons'
import iconInfo from '@graphcommerce/next-ui/icons/info.svg'
import { Trans } from '@lingui/react'
import { Box, ButtonBase, Link, Theme, Typography, useMediaQuery } from '@mui/material'
import React from 'react'

export type ProdustListItemBaseProps = {
  children?: React.ReactNode
} & ProductListItemFragment

export function ProductListItem(props: ProdustListItemBaseProps) {
  const { children, name, small_image, keurmerk } = props
  const productLink = useProductLink(props)

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

  return (
    <Box
      sx={(theme) => ({
        borderRadius: 1,
        boxShadow: 4,
        background: '#FAFAFA',
        width: '100%',
        maxWidth: theme.breakpoints.values.lg,
        mx: 'auto',
      })}
    >
      <ButtonBase
        href={productLink}
        sx={(theme) => ({
          overflow: 'hidden',
          display: 'grid',
          gridTemplateColumns: '82px 1fr',
          padding: `${theme.spacings.xxs} ${theme.spacings.xs}`,
          gap: theme.spacings.md,
          borderBottom: '1px solid #E5E8E9',
          alignItems: 'center',
          width: '100%',
          transition: 'background-color 0.15s ease',
          '&:hover': {
            backgroundColor: '#041c2c05',
          },
        })}
      >
        <Box sx={{ width: '82px', height: '82px' }}>
          {small_image && (
            <Image
              layout='fill'
              sizes='82px'
              src={small_image.url ?? ''}
              alt={small_image.label ?? ''}
              sx={{ display: 'block' }}
            />
          )}
        </Box>
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              component='h2'
              variant='body1'
              sx={{
                typography: { xs: 'body2', sm: 'subtitle2', md: 'subtitle1' },
                textAlign: 'left',
              }}
            >
              <strong>{name}</strong>
            </Typography>
            <Link
              component='div'
              color='primary'
              underline='hover'
              sx={{ display: 'flex', alignItems: 'center' }}
            >
              {isMobile ? <IconSvg src={iconInfo} size='large' /> : <Trans id='More info' />}
              <IconSvg src={iconChevronRight} />
            </Link>
          </Box>
          <Typography
            component='span'
            variant='subtitle2'
            sx={{ color: 'text.secondary', display: 'flex' }}
          >
            {keurmerk}
          </Typography>
        </Box>
      </ButtonBase>
      <Box>{children}</Box>
    </Box>
  )
}
