import { IconSvg, responsiveVal } from '@graphcommerce/next-ui'
import iconTransport from '@graphcommerce/next-ui/icons/transport.svg'
import { Trans } from '@lingui/react'
import { Box, SxProps, Theme } from '@mui/material'
import { DeliveryLabelFragment } from './DeliveryLabel.gql'

type DeliveryLabelProps = {
  sx?: SxProps<Theme>
} & DeliveryLabelFragment

export function ProductListItemDeliveryLabel(props: DeliveryLabelProps) {
  const { delivery_time, stock_status, sx = [] } = props

  const deliverable = delivery_time && stock_status === 'IN_STOCK'

  return (
    <Box sx={[{ display: 'flex', alignItems: 'center' }, ...(Array.isArray(sx) ? sx : [sx])]}>
      <Box
        component='span'
        sx={{
          width: responsiveVal(4, 8),
          height: responsiveVal(4, 8),
          borderRadius: responsiveVal(2, 4),
          backgroundColor: delivery_time ? 'primary.main' : 'action.disabled',
          marginRight: responsiveVal(4, 10),
          display: { xs: 'none', md: 'inline-block' },
        }}
      />
      <Box
        component='span'
        sx={{
          display: { xs: 'none', md: 'initial' },
          mr: '4px',
        }}
      >
        Levertijd{' '}
      </Box>
      <IconSvg src={iconTransport} sx={{ mr: '4px', display: { xs: 'initial', md: 'none' } }} />
      <Box
        component='span'
        sx={{
          color: deliverable ? 'primary.main' : 'action.disabled',
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
        }}
      >
        {!deliverable && <Trans id='Onbekend' />}
        {deliverable && (delivery_time === '1' ? `1 dag` : `${delivery_time} dagen`)}
      </Box>
    </Box>
  )
}
