import { useApolloClient } from '@apollo/client'
import { CustomerTokenDocument, useCustomerSession } from '@graphcommerce/magento-customer'
import { AddProductsToCartFab } from '@graphcommerce/magento-product'
import { ProductWishlistIconButton } from '@graphcommerce/magento-wishlist'
import { Button, IconSvg, responsiveVal } from '@graphcommerce/next-ui'
import iconShoppingCart from '@graphcommerce/next-ui/icons/cart.svg'
import { i18n } from '@lingui/core'
import { Trans } from '@lingui/react'
import { Box, styled } from '@mui/material'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { cutrBaseUrl } from '../../Cart/CutrCartItem/helpers'
import IconCircleSaw from '../../Layout/circle-saw.svg'
import { ProductListItemDeliveryLabel } from './DeliveryLabel'
import { PackSizeDropdown } from './PackSizeDropdown'
import { SimpleListPrice } from './SimpleListPrice'
import { SimpleProductRowFragment } from './SimpleProductRow.gql'
import { SimpleProductRowQuantityInputField } from './SimpleProductRowQuantityInputField'

type SimpleProductRowProps = SimpleProductRowFragment & {
  index: number
  optionLabel?: string | null
  optionValue?: string | null
}

const SimpleRow = styled('div')(({ theme }) =>
  theme.unstable_sx({
    typography: { xs: 'body2', sm: 'subtitle2', md: 'subtitle1' },
    background: '#fff',
    display: 'grid',
    padding: '13px 10px',
    '&:not(:last-child)': {
      borderBottom: 'solid 1px #E5E8E9',
    },
    gridTemplateAreas: {
      xs: `"text      text     text"
           "options   quantity actions"
           "msg       msg      msg"`,
      smd: `"attr options delivery quantity price actions"
           "msg  msg     msg      msg      msg   msg"`,
      lg: `"attr divider options delivery quantity price actions"
           "msg  msg     msg     msg      msg      msg   msg"`,
    },
    columnGap: { xs: '3px', smd: '15px', md: '20px', lg: '30px' },
    rowGap: { xs: '3px', smd: 0 },
    gridTemplateColumns: {
      xs: 'minmax(50px, auto) auto auto',
      smd: '1fr minmax(115px, 1fr) auto auto minmax(100px, auto) auto',
      lg: '1fr 1px minmax(115px, 1fr) minmax(min-content, 1fr) minmax(100px, auto) 1fr',
    },
    alignItems: 'center',
  }),
)

export const SimpleProductRow = React.memo<SimpleProductRowProps>((props) => {
  const {
    cutr_can_perform_operations,
    index,
    optionLabel,
    optionValue,
    price_range,
    sku,
    url_key,
  } = props
  const { loggedIn } = useCustomerSession()
  const client = useApolloClient()
  const router = useRouter()

  const currentEncryptedToken = client.readQuery({ query: CustomerTokenDocument })?.customerToken
    ?.encryptedTokens

  const authenticated = price_range?.minimum_price.final_price.value && loggedIn

  return (
    <SimpleRow>
      <Box
        sx={{
          gridArea: 'text',
          display: { xs: 'flex', smd: 'none' },
          flexWrap: 'wrap',
          columnGap: '10px',
          justifyContent: 'space-between',
        }}
      >
        <Box sx={{ whiteSpace: 'nowrap', minWidth: '70px' }}>
          {optionLabel} <strong>{optionValue}</strong>
        </Box>
        <ProductListItemDeliveryLabel {...props} />
        {(price_range?.minimum_price.final_price.value ?? 0) > 0 && <SimpleListPrice {...props} />}
        {!authenticated && (
          <Button
            color='primary'
            rel='nofollow'
            sx={{ color: 'primary.main', textDecorationColor: 'rgba(67, 176, 42, 0.4)' }}
            onClick={() =>
              router.push({
                pathname: '/account/signin',
                query: { urlKey: url_key },
              })
            }
          >
            <Trans id='Check your prices' />
          </Button>
        )}
      </Box>

      {optionLabel && optionValue && (
        <Box
          className='attributes'
          sx={{
            gridArea: 'attr',
            display: { xs: 'none', smd: 'block' },
            whiteSpace: 'nowrap',
          }}
        >
          <Box component='span'>{optionLabel} </Box>
          <Box
            component='span'
            sx={(theme) => ({
              color: 'text.primary',
              fontWeight: 900,
              [theme.breakpoints.up('md')]: {
                backgroundColor: 'rgba(242,243,244)',
                padding: `${responsiveVal(2, 8)} ${responsiveVal(4, 14)}`,
                marginLeft: '10px',
                borderRadius: '1px',
                boxShadow: 1,
              },
            })}
          >
            {optionValue}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          gridArea: 'divider',
          height: 'calc(100% + 2 * 13px)',
          marginTop: '-13px',
          borderLeft: 'dashed 1px #E5E8E9',
          overflow: 'visable',
          marginRight: { xs: '5px', lg: '0' },
          display: { xs: 'none', lg: 'block' },
        }}
      />
      <Box gridArea='options'>
        <PackSizeDropdown {...props} />
      </Box>
      <ProductListItemDeliveryLabel
        {...props}
        sx={{ gridArea: 'delivery', display: { xs: 'none', smd: 'flex' } }}
      />
      <SimpleProductRowQuantityInputField {...props} />
      <Box
        sx={{
          gridArea: 'price',
          display: { xs: 'none', smd: 'flex' },
          justifyContent: 'center',
        }}
      >
        <SimpleListPrice {...props} />
        {!authenticated && (
          <Button
            color='primary'
            rel='nofollow'
            onClick={() =>
              router.push({
                pathname: '/account/signin',
                query: { urlKey: url_key },
              })
            }
          >
            <Trans id='Check your prices' />
          </Button>
        )}
      </Box>

      <Box
        sx={{
          display: 'grid',
          gridArea: 'actions',
          gridAutoFlow: 'column',
          gridTemplateColumns: 'min-content min-content',
          columnGap: { xs: '3px', md: 1 },
          justifyContent: 'end',
        }}
      >
        <ProductWishlistIconButton {...props} sx={{ padding: '8.9px' }} rating_summary={0} />
        <Button
          href={`${cutrBaseUrl}/open?token=${currentEncryptedToken}&material=${sku}`}
          target='_self'
          color='inherit'
          variant='pill'
          size='small'
          disabled={!authenticated || !cutr_can_perform_operations}
          sx={{
            backgroundColor:
              !authenticated || !cutr_can_perform_operations ? '#0000001f' : '#F3F3F3',
            '&:hover': { backgroundColor: '#e0e0e0' },
            minWidth: '40px',
            height: '40px',
            px: 0,
          }}
        >
          <IconSvg src={IconCircleSaw} size='medium' />
        </Button>
        <AddProductsToCartFab
          disabled={!authenticated}
          size='small'
          aria-label={i18n._(/* i18n */ 'Add to Cart')}
          sku={sku}
          index={index}
          sx={{ zIndex: 1 }}
          icon={iconShoppingCart}
          color='primary'
        />
      </Box>
    </SimpleRow>
  )
})
