import { useCustomerQuery } from '@graphcommerce/magento-customer'
import { useMemo } from 'react'
import { useOpmaatCustomerSession } from '../MagentoCustomer/OpmaatCustomerSession/useOpmaatCustomerSession'
import {
  CustomerDataListConfigurableFragment,
  CustomerDataListSimpleFragment,
} from './CustomerDataList.gql'
import { UseCustomerDataListDocument, UseCustomerDataListQuery } from './UseCustomerDataList.gql'

function findUrlKeys<T extends UseCustomerDataListQuery['products']>(products: T): string[] {
  return (products?.items ?? []).map((item) => item?.url_key).filter(Boolean) as string[]
}

function replaceSimple<T extends CustomerDataListSimpleFragment>(
  current: T,
  incomming: CustomerDataListSimpleFragment,
): T {
  return {
    ...current,
    // stock: { ...current.stock, ...incomming.stock },
    delivery_time: incomming.delivery_time,
    stock_status: incomming.stock_status,
    price_range: {
      ...current.price_range,
      ...incomming.price_range,
    },
  }
}

function replaceConfigurable<T extends CustomerDataListConfigurableFragment>(
  current: T,
  incomming: CustomerDataListConfigurableFragment,
): T {
  const variants = (current.variants ?? [])?.map((currV) => {
    const incV = incomming.variants?.find(
      (inc) => inc?.product?.uid && currV?.product?.uid && inc.product.uid === currV.product.uid,
    )
    if (!currV?.product || !incV?.product) return currV
    return { ...currV, product: replaceSimple(currV.product, incV.product) }
  })

  return {
    ...current,
    variants,
    price_range: {
      ...current.price_range,
      ...incomming.price_range,
    },
  }
}

export function useCustomerDataList<T extends UseCustomerDataListQuery['products']>(
  products: T,
): T {
  const urlKeys = findUrlKeys(products)
  const { status } = useOpmaatCustomerSession()
  const { data } = useCustomerQuery(UseCustomerDataListDocument, {
    variables: { urlKeys, pageSize: 250 },
    skip: urlKeys.length === 0 || status !== 'ACTIVE',
    fetchPolicy: 'cache-and-network',
  })

  const updatedProducts: T = useMemo(
    () =>
      ({
        ...products,
        items: products?.items?.map((item) => {
          const foundItem = data?.products?.items?.find((i) => item?.uid && i?.uid === item.uid)
          if (!item || !foundItem) return item

          const iType = item?.__typename
          const fType = foundItem?.__typename

          if (iType === 'ConfigurableProduct' && fType === 'ConfigurableProduct')
            return replaceConfigurable(item, foundItem)

          if (iType === 'SimpleProduct' && fType === 'SimpleProduct')
            return replaceSimple(item, foundItem)

          console.warn(
            `Product type not supported for ${item.uid}: ${item.__typename} and ${foundItem.__typename}`,
          )

          return item
        }),
      }) as T,
    [data?.products?.items, products],
  )

  return updatedProducts
}
