import { Money } from '@graphcommerce/magento-store'
import { Box, SxProps, Theme } from '@mui/material'
import { useConfigurableOptionsSelection } from '../../ProductPage/useConfigurableOptionsSelection'
import { SimpleListPriceFragment } from './SimpleListPrice.gql'

type SimpleListPriceProps = SimpleListPriceFragment & {
  sx?: SxProps<Theme>
  index?: number
  url_key?: string | null
}

export function formatUnitValue() {}

export function formatUnit(incommingUnit: string) {
  let unit = `${incommingUnit || 'stuk'}`

  if (unit === 'm2') unit = 'm²'
  if (unit === 'm3') unit = 'm³'
  if (unit === 'm1') unit = 'm¹'

  return unit
}

export function SimpleListPrice(props: SimpleListPriceProps) {
  const { price_range, sx = [] } = props
  const showUnit = (price_range?.minimum_price.unit_price?.value ?? 0) > 0
  const showPrice = !showUnit && (price_range?.minimum_price.final_price.value ?? 0) > 0

  const unit = `/${formatUnit(price_range?.minimum_price.unit ?? '')}`

  return (
    <Box
      sx={[
        {
          display: 'grid',
          gridAutoFlow: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          columnGap: '6px',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {showUnit && (
        <>
          <strong style={{ marginRight: -5 }}>
            <Money {...price_range?.minimum_price.unit_price} />
          </strong>
          <span>{unit}</span>
        </>
      )}
      {showPrice && (
        <>
          <strong style={{ marginRight: -5 }}>
            <Money {...price_range?.minimum_price.final_price} />
          </strong>
          <span>{unit}</span>
        </>
      )}
      {!showUnit && !showPrice && <div style={{ width: '70px' }} />}
    </Box>
  )
}

export function SimpleListPriceConfigurable(props: SimpleListPriceProps) {
  const { index = 0, url_key, price_range } = props
  const { configured } = useConfigurableOptionsSelection({ url_key, index })
  const new_price_range =
    configured?.configurable_product_options_selection?.variant?.price_range ?? price_range

  return <SimpleListPrice {...props} price_range={new_price_range} />
}
