import { NumberFieldElement } from '@graphcommerce/ecommerce-ui'
import { useFormAddProductsToCart } from '@graphcommerce/magento-product'
import { Box } from '@mui/material'
import { SimpleProductRowFragment } from './SimpleProductRow.gql'

type SimpleProductRowQuantityInputFieldProps = SimpleProductRowFragment & {
  index: number
}

export function SimpleProductRowQuantityInputField(props: SimpleProductRowQuantityInputFieldProps) {
  const { index } = props
  const { control } = useFormAddProductsToCart()

  return (
    <Box sx={{ gridArea: 'quantity', justifySelf: 'start' }}>
      <NumberFieldElement
        size='small'
        defaultValue={1}
        control={control}
        name={`cartItems.${index}.quantity`}
        sx={{ zIndex: 1, width: 'max(70px, min((70px + 3.13vw), 120px))' }}
      />
    </Box>
  )
}
