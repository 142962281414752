import { useApolloClient, useQuery } from '@graphcommerce/graphql'
import { useCustomerSession } from '@graphcommerce/magento-customer'
import { CustomerStatusDocument } from './CustomerStatus.gql'

export enum CustomerStatus {
  SIGNEDOUT = 'SIGNEDOUT',
  UNKNOWN = 'UNKNOWN',
  ACTIVE = 'ACTIVE',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  INACTIVE = 'INACTIVE',
  EMAIL_UNASSOCIATED = 'EMAIL_UNASSOCIATED',
  EMAIL_UNRECOGNIZED = 'EMAIL_UNRECOGNIZED',
  EMAIL_MULTIPLE_MATCHES = 'EMAIL_MULTIPLE_MATCHES',
}

export function useCustomerStatus() {
  const { cache } = useApolloClient()

  const res = cache.readQuery({ query: CustomerStatusDocument })
  const session = useCustomerSession()

  const query = useQuery(CustomerStatusDocument, {
    skip: !session.token || !session.valid,
    fetchPolicy:
      res?.customer?.opMaat_contactPersonStatus === CustomerStatus.ACTIVE && session.loggedIn
        ? 'cache-only'
        : 'network-only',
  })

  const status = query.data?.customer
    ? query.data?.customer?.opMaat_contactPersonStatus ?? CustomerStatus.UNKNOWN
    : CustomerStatus.SIGNEDOUT

  return { ...query, status }
}
