import {
  ProductListItem_ConfigurableProduct_Fragment,
  useProductLink,
} from '@graphcommerce/magento-product'
import { Button, IconSvg, iconArrowForward } from '@graphcommerce/next-ui'
import { Box } from '@mui/material'
import { ProductListItem } from './ProductListItem'
import { SimpleListPrice } from './SimpleProductRow/SimpleListPrice'
import { SimpleProductRow } from './SimpleProductRow/SimpleProductRow'

export function ProductListItemConfigurable(
  props: ProductListItem_ConfigurableProduct_Fragment & { index: number },
) {
  const { configurable_options, variants, index: configurable_index } = props

  const link = useProductLink(props)
  const count = configurable_options?.length ?? 0

  const foundUid: string[] = []

  return (
    <ProductListItem {...props}>
      {count === 1 &&
        variants?.map((variant, index) => {
          if (!variant?.product?.uid || foundUid.includes(variant.product?.uid)) return null

          const res = (variant.attributes ?? []).map((value) => ({
            value_label: value?.label,
            value: value?.uid,
            attr_label: configurable_options?.find(
              (option) => option?.attribute_code === value?.code,
            )?.label,
          }))
          foundUid.push(variant.product.uid)

          return (
            <SimpleProductRow
              {...variant.product}
              key={variant.product?.uid}
              index={configurable_index * 100 + index}
              optionLabel={res[0]?.attr_label}
              optionValue={res[0]?.value_label}
            />
          )
        })}

      {count > 1 && (
        <Box
          sx={(theme) => ({
            backgroundColor: '#fff',
            padding: theme.spacings.xs,
            display: 'grid',
            justifyContent: 'end',
          })}
        >
          <SimpleListPrice {...props} />
          <Button
            href={link}
            variant='pill'
            color='primary'
            size='small'
            endIcon={<IconSvg src={iconArrowForward} />}
          >
            Configure
          </Button>
        </Box>
      )}
    </ProductListItem>
  )
}
