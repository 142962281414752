import { useCustomerSession } from '@graphcommerce/magento-customer'
import { CustomerStatus, useCustomerStatus } from '../../useCustomerStatus/useCustomerStatus'

export function useOpmaatCustomerSession() {
  const { loggedIn, query, ...token } = useCustomerSession()
  const { status } = useCustomerStatus()

  const active = status === CustomerStatus.ACTIVE

  return {
    loggedIn: loggedIn && active,
    query,
    status,
    ...token,
  }
}
